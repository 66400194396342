import React from "react";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import InputComponent from '../../../common/form/input';

const ForgotPasswordForm = props => {
    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={props.onSubmit}
            validationSchema={ValidationSchema}
        >
            {({handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                    <div className='form-item'>
                        <Field 
                            name='email'
                            type='text'
                            value={values.email}
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='email'
                                    type='text'
                                    label='E-mail'
                                />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='email' />
                    </div>
                    <div className='text-center'>
                        <button disabled={props.loading} type='submit' className='button button--primary button-half-width'>Reset</button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

const ValidationSchema = Yup.object({
    email: Yup.string().email('Please enter valid email id').required('Please enter email')
})

export default ForgotPasswordForm;