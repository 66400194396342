import React from "react";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import InputComponent from '../../../common/form/input';

const ResetPasswordForm = props => {
    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validationSchema={ValidationSchema}
            enableReinitialize
        >
            {({handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                <div className='form-item'>
                        <Field 
                            name='email'
                            type='text'
                            value={values.email}
                            readonly
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='email'
                                    type='text'
                                    placeholder='E-mail'
                                    readonly
                                />)}
                        </Field>
                    </div>
                    <div className='form-item'>
                        <Field 
                            name='code'
                            type='number'
                            value={values.code}
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='code'
                                    type='number'
                                    placeholder='Code'
                                />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='code' />
                    </div>
                    <div className='form-item'>
                        <Field 
                            name='password'
                            type='password'
                            value={values.password}
                        >
                            {props => (<InputComponent 
                                name='password'
                                {...props}
                                type='password'
                                placeholder='Password'
                            />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='password' />
                    </div>
                    <div className='text-center'>
                        <button disabled={props.loading} type='submit' className='button button--primary button-half-width'>Reset</button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

const ValidationSchema = Yup.object({
    email: Yup.string().email('Please enter valid email id').required('Please enter email'),
    code: Yup.string().required('Please enter code').min(6, 'Please enter valid code').max(6, 'Please enter valid code'),
    password: Yup.string().required('Please enter password')
})

export default ResetPasswordForm;