import * as types from "../action-types";
import { checkLogin } from "../authService";

const initialState = {
    auth: checkLogin(),
    authLoading: false,
    notification: {
        type: '',
        message: ''
    },
    forgotPassEmail: '',
    isResetPassword: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_INIT:
        case types.SIGNUP_INIT:
        case types.RESET_PASSWORD_INIT:
        case types.FORGOT_PASSWORD_INIT:
            return {
                ...state,
                authLoading: true,
                isResetPassword: false
            }
        case types.FORGOT_PASSWORD_SUCCESS:
        return {
            ...state,
            forgotPassEmail: action.payload.email,
            authLoading: false,
            notification: {
                type: 'success',
                message: action.payload.message
            }
        }
        case types.AUTH_SUCCESS:
        case types.SIGNUP_SUCCESS:
            return {
                ...state,
                authLoading: false,
                auth: true
            };
        case types.RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            authLoading: false,
            forgotPassEmail: '',
            isResetPassword: true,
            notification: {
                type: 'success',
                message: action.payload.message
            }
        }
        case types.AUTH_ERROR:
        case types.SIGNUP_ERROR:
        case types.FORGOT_PASSWORD_ERROR:
        case types.RESET_PASSWORD_ERROR:
            return {
                ...state,
                authLoading: false,
                notification: {
                    type: 'error',
                    message: action.payload.message
                }
            }
        case 'REMOVE_NOTIFICATION':
            return {
                ...state,
                notification: {
                    type: '',
                    message: ''
                }
            }
        case 'ADD_NOTIFICATION':
            return {
                ...state,
                notification: {
                    type: action.payload.type,
                    message: action.payload.message
                }
            }
        case 'LOGOUT':
            return {
                ...state,
                auth: false
            }
        default:
            return state;
    }
}

export default reducer;