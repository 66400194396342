import React from 'react';

const Footer = () => {
    return(
        <footer className='footer'>
           <div className='top-footer'>
              <ul>
                <li> <a href='tel:0212-555-0188'><i className="fa fa-phone" aria-hidden="true"/>0212-555-0188</a></li>
                <li> <a href='mailto:info@testroutes.co.uk'><i className="fa fa-envelope-o" aria-hidden="true"/>
info@testroutes.co.uk</a></li>
              </ul>
           </div>
           <div className='bottom-footer'>
            <p className='text-center'>
                    Copyright © {(new Date()).getFullYear()}
                <a href='https://www.testroutes.co.uk/' target='_blank' rel="noopener noreferrer" className=' ml-1'>Test Routes.</a>
                </p>
                <ul className='ml-0 pl-0 d-flex align-items-center justify-content-center footer-elements'>
                <li className=''>
                    <a className='' href='https://www.testroutes.co.uk/privacy-policy/'>
                            Privacy Policy
                    </a>
                    </li>
                    <li className=''>
                        <a className='' href='https://www.testroutes.co.uk/terms-and-conditions/'>
                            Terms and Conditions
                        </a>
                    </li>
                    {/* <li>
                        <a className='text-primary' href='https://www.testroutes.co.uk/contact-us/'>
                            Contact
                        </a>
                    </li> */}
                </ul>
           </div>
        </footer>

    );
}

export default Footer;
