import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createAction } from '../utility';

const Notification = props => {
    if (props.notification.type && props.notification.message) {
        toast[props.notification.type](props.notification.message);
        setTimeout(props.removeNotification, 3000);
    }

    return (
        <ToastContainer 
            position='top-right'
            autoClose={3000}
            hideProgressBar
            closeOnClick
            pauseOnHover
        />
    );
}

const mapStateToProps = state => ({
    notification: state.common.notification
});

const mapDispatchToProps = dispatch => ({
    removeNotification: () => dispatch(createAction('REMOVE_NOTIFICATION'))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification);